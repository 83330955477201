import { DirtyPage } from './dirty-page';

export class Utilities {
  constructor() {
    this.init();
  }

  init() {
    this.addUtilitiesInWindow();
    this.addEventListenersInWindow();
  }

  addUtilitiesInWindow() {
    window['DirtyPage'] = new DirtyPage();
  }

  addEventListenersInWindow() {
    window.addEventListener('beforeunload', () => {
      // TODO: launch ui core confirm dialog, if page change is active
    });
  }
}

export const utilities = new Utilities();
