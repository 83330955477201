class ScriptLoader {
  constructor() {}

  loadAll(urls: string[]) {
    const promises: any[] = [];
    urls.forEach((url) => promises.push(this.load(url)));
    return Promise.all(promises);
  }

  load(url: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.setAttribute('src', url);

      script.onload = () => {
        // Use removeChild to avoid polyfilling Element.remove();
        script.parentElement.removeChild(script);
        resolve(true);
      };

      script.onerror = () => {
        // Use removeChild to avoid polyfilling Element.remove();
        script.parentElement.removeChild(script);
        reject(new Error(`Error loading script from ${url}`));
      };

      document.documentElement.appendChild(script);
    });
  }
}

export const scriptLoader = new ScriptLoader();
