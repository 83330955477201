import { serviceDownComponent } from '../application';
import { MicroApp } from './models';
import { configLoader } from './services';

type PathFilter = { includes: string[] } | { excludes: string[] } | { includes: string[]; excludes: string[] };

interface MicroAppConfig {
  name: string;
  path: PathFilter;
  isSystemJS?: boolean;
}

class MicroAppRegistryService {
  private microAppsRegistry: Map<string, MicroApp>;

  constructor() {
    this.microAppsRegistry = new Map();
  }

  async init(): Promise<Map<string, MicroApp>> {
    try {
      const microAppsConfigs = await configLoader.loadJSON<MicroAppConfig[]>('../assets/micro-apps-registry.json');
      microAppsConfigs.forEach((config) => {
        const { name, path, isSystemJS = false } = config;
        this.microAppsRegistry.set(
          name,
          new MicroApp(name, (location) => this.shouldLoadMicroApp(location, path), isSystemJS)
        );
      });

      return this.microAppsRegistry;
    } catch (error) {
      serviceDownComponent.show();
      throw error;
    }
  }

  private shouldLoadMicroApp(location: Location, path: PathFilter): boolean {
    const { pathname } = location;
    const includes = 'includes' in path ? path.includes : [];
    const excludes = 'excludes' in path ? path.excludes : [];

    return (
      (includes.length === 0 || includes.some((include) => pathname.startsWith(include))) &&
      (excludes.length === 0 || !excludes.some((exclude) => pathname.startsWith(exclude)))
    );
  }
}

export const microAppRegistryService = new MicroAppRegistryService();
