/**
 * This should be a collection of functions and features to ease the development process,
 * these features should be disabled by default in certain environments like: production, demo etc.
 */
import { BundleConfig } from '../models';

/**
 * Currently not in use, but this could be an example of toggles:
 * ```
 * import * as devtools from './utilities/devtools';
 *
 * if (window[devtools.KEY]) {
 *   bundleConfig = devtools.fromLocal(bundleConfig);
 * }
 * ```
 */
export const KEY = '__CATALYSTONE_DEVTOOLS__';

/**
 * query parameter for bundle name
 */
const FL = 'from_local';

/**
 * query parameter for bundle source
 */
const FLS = 'from_local_src';

/**
 * query parameter to clear local storage entries
 */
const CLS = 'clear_storage';

/**
 * local storage key for bundles
 */
const LOCAL_STORAGE_KEY = 'local_bundles';

/**
 * URL pattern for bundles
 */
const URL_PATTERN = /^https:\/\/([\w]+[.]?([-]?[\w]+))*(\:[\d]{3,5})?\/([\w-./]*)*\.js$/;

/**
 * Overrides existing entry in configuration from queryParameters from_local (name) and from_local_src (link)
 * ```
 * // https://app.istio.catalystonedev.ninja/?from_local=portals-ui&from_local_src=http://localhost:4201/main.js
 * const newBundleConfig = fromLocal(oldBundleConfig);
 * ```
 * @param collection the collection that should be updated.
 */
const fromLocalSource = (collection: BundleConfig[], url: URL): BundleConfig[] => {
  return collection.map((bundle) => {
    if (bundle.name === url.searchParams.get(FL)) {
      bundle.url = url.searchParams.get(FLS);
      return bundle;
    }
    return bundle;
  });
};

/**
 * Overrides existing entry in configuration using localStorage.
 * ```
 * localStorage.setItem('local_bundles', JSON.stringify(BundleConfig[]))
 * ```
 * @param collection the collection that should be updated.
 */
const fromLocalStorage = (collection: BundleConfig[]): BundleConfig[] => {
  const bundlesString = localStorage.getItem(LOCAL_STORAGE_KEY);
  try {
    let bundles: BundleConfig[] = null;
    if (bundlesString && bundlesString.length > 0) {
      bundles = JSON.parse(bundlesString) as BundleConfig[];
      collection.forEach((bundle) => {
        const entry = bundles.find((bnd) => bundle.name === bnd.name);
        if (entry) {
          bundle.url = entry.url;
          bundle.isSystemJS = entry.isSystemJS;
        }
        return bundle;
      });
      // allow local bundles, even if there's no published versions yet...
      const remote = collection.map((val) => val.name);
      const local = bundles.map((a) => a.name).filter((l) => !remote.includes(l));
      local.forEach((l) => {
        const localBundle = bundles.find((b) => b.name === l);
        collection.push({
          name: localBundle.name,
          url: localBundle.url,
          isSystemJS: localBundle.isSystemJS
        });
      });
    }
  } catch (err) {
    console.error('Error occurred while parsing bundle config in localStorage');
  }
  return collection;
};

/**
 * function to clear the bundles from localStorage
 */
const clearFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  document.location.reload();
};

/**
 * store
 * @param bundles array of Bundles
 */
const addToLocalStorage = (bundles: BundleConfig[]): void => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(bundles));
  document.location.reload();
};

/**
 * devtools
 * @param collection the collection that may be updated.
 */
export const bundleDevtools = (collection: BundleConfig[]): BundleConfig[] => {
  const url = new URL(location.href);

  if (url.searchParams.has(CLS)) {
    clearFromLocalStorage();
  } else if (url.searchParams.has(FL) && url.searchParams.has(FLS)) {
    collection = fromLocalSource(collection, url);
  } else if (localStorage.getItem(LOCAL_STORAGE_KEY)) {
    collection = fromLocalStorage(collection);
  }

  return collection;
};

/**
 * Toggle the editor
 */
const toggle = (): void => {
  document.getElementById('devtools-editor').classList.toggle('show-devtools');
};

/**
 * Create the trigger anchor
 */
const createTrigger = (): void => {
  const trigger = document.createElement('a');
  trigger.classList.add('devtools-trigger');
  trigger.innerText = 'DEVTOOLS';
  trigger.addEventListener('click', toggle);
  document.documentElement.appendChild(trigger);
};

/**
 * Save function to override a configuration
 * @param event event
 */
const save = (event: Event): void => {
  event.preventDefault();

  const form = new FormData(event.target as HTMLFormElement);
  const name = form.get('name') as string;
  const url = form.get('url') as string;
  const isSystemJS = Boolean(form.get('moduleType'));

  if (URL_PATTERN.test(url)) {
    addToLocalStorage([{ name, url, isSystemJS }]);
  } else {
    console.log(`Entry: { name: ${name}, url: ${url} } was not added, because the URL was invalid`);
  }
};

/**
 * Create the editor
 * @param apps registered apps
 */
const createEditor = (registeredApps: string[]): void => {
  // Wrapper
  const editor = document.createElement('div');
  editor.classList.add('devtools-content');
  editor.setAttribute('id', 'devtools-editor');

  // Form
  const form = document.createElement('form');
  form.addEventListener('submit', save);

  // Container
  const container = document.createElement('div');
  container.classList.add('container');
  container.classList.add('p-2');

  // Header
  const header = document.createElement('h5');
  header.innerText = 'Override a micro-app';
  container.appendChild(header);

  // Select Group
  const selectGroup = document.createElement('div');
  selectGroup.classList.add('form-group');
  const select = document.createElement('select');
  select.classList.add('form-control');
  select.name = 'name';
  select.id = 'micro-apps';
  registeredApps.sort();
  registeredApps.forEach((app) => {
    const opt = document.createElement('option');
    opt.value = app;
    opt.text = app;
    select.appendChild(opt);
  });
  const selectLabel = document.createElement('label');
  selectLabel.innerText = 'Micro-app';
  selectLabel.htmlFor = 'micro-apps';
  selectGroup.appendChild(selectLabel);
  selectGroup.appendChild(select);
  container.appendChild(selectGroup);

  // URL Group
  const urlGroup = document.createElement('div');
  selectGroup.classList.add('form-group');
  const url = document.createElement('input');
  url.classList.add('form-control');
  url.type = 'url';
  url.name = 'url';
  url.id = 'url';
  url.placeholder = 'e.g: https://localhost:4200/main.js';
  const urlLabel = document.createElement('label');
  urlLabel.innerText = 'URL';
  urlLabel.htmlFor = 'url';
  urlGroup.appendChild(urlLabel);
  urlGroup.appendChild(url);
  container.appendChild(urlGroup);

  // module type checkbox
  const moduleTypeGroup = document.createElement('div');

  const moduleTypeLabel = document.createElement('label');
  moduleTypeLabel.innerText = 'SystemJS module';
  moduleTypeLabel.setAttribute('htmlFor', 'moduleType');

  const moduleType = document.createElement('input');
  moduleType.setAttribute('type', 'checkbox');
  moduleType.setAttribute('id', 'moduleType');
  moduleType.classList.add('form-control');
  moduleType.name = 'moduleType';
  moduleType.id = 'moduleType';

  moduleTypeGroup.append(moduleTypeLabel);
  moduleTypeGroup.append(moduleType);

  container.appendChild(moduleTypeGroup);

  // Buttons
  const buttonGroup = document.createElement('div');
  buttonGroup.classList.add('p-2');

  const saveButton = document.createElement('button');
  saveButton.innerText = 'Save';
  saveButton.classList.add('btn', 'btn-primary');
  saveButton.type = 'submit';
  buttonGroup.appendChild(saveButton);
  const clearButton = document.createElement('button');
  clearButton.innerText = 'Clear';
  clearButton.classList.add('btn', 'btn-warning');
  clearButton.type = 'button';
  clearButton.addEventListener('click', clearFromLocalStorage);
  buttonGroup.appendChild(clearButton);
  container.appendChild(buttonGroup);
  document.documentElement.appendChild(editor).appendChild(form).appendChild(container);
};

/**
 * Programmatically add a devtool UI to override bundles
 * @param apps registered micro-apps in the MicroFrontendService
 */
export const createDevTools = (apps: string[]): void => {
  createTrigger();
  createEditor(apps);
};
