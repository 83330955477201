import { encrypt } from 'crypto-js/aes';
import * as SingleSpa from 'single-spa';
import { stateService } from '../../application';
import { JwtToken, MessageData, RedirectData } from '../models';

class MessageService {
  constructor() {}

  bindEvents() {
    window.addEventListener(
      'ssc-event',
      (event: CustomEvent<MessageData>) => {
        this.receiveMessage(event);
      },
      false
    );
  }

  private receiveMessage(event: CustomEvent<MessageData>): void {
    const message = event.detail;
    if (message) {
      switch (message.type) {
        case 'REDIRECT_ACTION':
          // Encrypting to mask URL.
          const encryptedURL = this.getEncryptedMonoURL((message as RedirectData).redirect.target);
          SingleSpa.navigateToUrl(encryptedURL);
          break;
        case 'NO_ACTION':
          console.log('Event was dispatched, but had no action');
          break;
        default:
          console.log('Function not implemented...');
      }
    }
  }

  private getEncryptedMonoURL(url: string): string {
    let encryptedURL = url;

    if (url && stateService.getSnapshot()?.auth?.token) {
      const matches = url.match(/t=([^&]*)/);
      const t = matches[1];

      // Encrypting to mask URL.
      const token = new JwtToken(stateService.getSnapshot().auth.token.toString());
      const key = token.getTenantId();
      encryptedURL = encrypt(decodeURIComponent(t.trim()), key.trim()).toString();
      encryptedURL = url.replace(t, encodeURIComponent(encryptedURL));
    }

    return encryptedURL;
  }
}

export const messageService = new MessageService();
