import { Observable } from 'rxjs/internal/Observable';
import { from } from 'rxjs/internal/observable/from';
import { catchError } from 'rxjs/internal/operators/catchError';
import { switchMap } from 'rxjs/internal/operators/switchMap';

import { stateService, StateService, telemetryService } from '../../application';
import { JwtToken, language } from '../models';
import { configLoader } from './config-loader';

class LanguageService {
  readonly defaultLocaleKey = 3; // Representing English (United Kingdom)
  globalLocale: string;
  userLocale: string;

  locales = new Map<number, string>([
    [1, 'nb-NO'],
    [2, 'en-US'],
    [3, 'en-GB'],
    [4, 'fi-FI'],
    [5, 'da-DK'],
    [6, 'sv-SE'],
    [7, 'de-DE'],
    [8, 'nl-NL'],
    [9, 'pl-PL'],
    [10, 'fr-FR']
  ]);

  constructor(private readonly state: StateService) {}

  subscribeToUserLocale = (): Observable<string> => {
    return this.state.get().pipe(
      switchMap(() => {
        return from(this.getLocaleToLoad());
      }),
      catchError(() => this.getDefaultLocale())
    );
  };

  async getLocaleToLoad(): Promise<string> {
    this.userLocale = this.getUserLocaleIfSessionAlive() || this.state.getSnapshot().userLocale;
    if (this.userLocale) {
      return this.userLocale;
    }

    this.globalLocale = await this.getGlobalLocale();
    return this.globalLocale;
  }

  private getUserLocaleIfSessionAlive(): language | undefined {
    const sessionTokenString = sessionStorage.getItem(JwtToken.JWT_TOKEN_KEY);
    if (!sessionTokenString) {
      return;
    }

    try {
      const jwtToken = new JwtToken(sessionTokenString);
      if (!jwtToken.isExpired()) {
        return jwtToken.getLocale();
      }
    } catch (err) {
      return;
    }
  }

  private readonly getGlobalLocale = async (): Promise<string> => {
    if (this.globalLocale) {
      return Promise.resolve(this.globalLocale);
    }
    return configLoader
      .loadJSON<number>('mono/rest/languages/default')
      .then((localeId: number) => {
        this.globalLocale = this.locales.get(localeId);
        return this.globalLocale;
      })
      .catch((error) => {
        telemetryService.logException(new Error(`Error loading default language for the application: ${error}`));
        return Promise.resolve(this.getDefaultLocale());
      });
  };

  private getDefaultLocale(): string {
    return this.locales.get(this.defaultLocaleKey);
  }
}

export const languageService = new LanguageService(stateService);
