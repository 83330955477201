const predicates = [(location: Location) => location.pathname === '/'];

type ActivityFunctionReturn = (location: Location) => boolean;

export const convertToActivityFunction = (functionString: string): ActivityFunctionReturn => {
  const functionArray = functionString.split(' ');
  if (functionArray[0] === 'true') {
    return () => true;
  }

  const predicate =
    functionArray[1] === 'eq'
      ? (location: Location) => location.pathname[functionArray[2]](functionArray[3])
      : (location: Location) => !location.pathname[functionArray[2]](functionArray[3]);
  predicates.push(predicate);

  return predicate;
};

export const activityFunctionNotFound = (): ActivityFunctionReturn => {
  return (location: Location) => predicates.every((func) => !func(location));
};

export const fulfillsAnyPredicate = (location: Location): boolean => {
  return predicates.some((func) => func(location));
};
