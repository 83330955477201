class ConfigLoader {
  constructor() {}

  loadJSON<T>(url: string | RequestInfo) {
    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then((res) => res.json() as Promise<T>);
  }
}

export const configLoader = new ConfigLoader();
