import { stateService } from '../state/state.service';
import './service-down-card.component.scss';

class ServiceDownComponent {
  private readonly _serviceDownCardWrapper = document.getElementById('cx-service-down-card-wrapper');

  show(): void {
    this._serviceDownCardWrapper.classList.add('show');
    stateService.set({ isLoading: false });
  }
}

export const serviceDownComponent = new ServiceDownComponent();
