import { Axios } from 'axios';
import { environmentService } from '../src/micro-front-end/environment.service';
import { BundleConfig } from '../src/micro-front-end/models';
import { AxiosConfigurationService, configureAxiosInterceptor } from './axios.configuration';
import * as IMPORTS_MAP from './import-map.json';

export const registerSystemJSImportMap = (bundleConfig: BundleConfig[]): void => {
  // init with default configuration
  const imports = bundleConfig
    .filter(({ isSystemJS }) => isSystemJS)
    .reduce((config, { name, url }) => ({ ...config, [name]: url }), IMPORTS_MAP);

  System.addImportMap({ imports });
};

// load and configure global dependencies
export const configureSystemJSDependencies = () => {
  return new Promise<void>(function (resolve, reject) {
    System.import('axios')
      .then((axios: Axios) => {
        configureAxiosInterceptor(axios);
        // cdn unprotected by default, skip token injection
        AxiosConfigurationService.getInstance().registerUnprotectedResources([environmentService.env.cdnUrl]);
        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};
