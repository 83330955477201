import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { ApplicationState } from '../shared/application-state.model';

function getWindow(): any {
  return window;
}

export interface StateService {
  init: () => void;
  get: () => Observable<ApplicationState>;
  set: (newState: Partial<ApplicationState>) => void;
  getSnapshot: () => ApplicationState;
}
/**
 * Simple state management in window
 */
class StateServiceImpl implements StateService {
  private _subject: BehaviorSubject<ApplicationState>;
  private readonly _initialState: ApplicationState = {
    isMicroFrontEnd: true,
    openedDialogCounter: 0,
    openedOverlayCounter: 0
  };

  init(): void {
    this._subject = new BehaviorSubject(this._initialState);
    getWindow()['__state'] = {
      get: this.get,
      set: this.set,
      getSnapshot: this.getSnapshot
    };
  }
  /**
   * Gets the observable state
   */
  get = (): Observable<ApplicationState> => this._subject.asObservable();

  /**
   * Mutates the state
   */
  set = (newState: Partial<ApplicationState>): void => {
    const state = {
      ...this._subject.getValue(),
      ...newState
    };
    this._subject.next(state);
  };

  /**
   * Gets a snapshot of the current state
   */
  getSnapshot = (): ApplicationState => {
    return { ...this._subject.getValue() };
  };
}

export const stateService = new StateServiceImpl();
