import * as SingleSpa from 'single-spa';
import { telemetryService } from '../../application/telemetry/telemetry.service';
import { scriptLoader } from '../services/script-loader.service';

export type ActivityFunc = (location: Location) => boolean;

export class MicroApp {
  public version: string;
  public url: string;
  public isSystemJS: boolean;

  constructor(
    public name: string,
    public activityFunction: ActivityFunc,
    isSystemJS = false
  ) {
    this.isSystemJS = isSystemJS;
  }

  isMounted(): boolean {
    return (
      SingleSpa.getAppNames().findIndex((app) => this.name === app) !== -1 &&
      SingleSpa.getAppStatus(this.name) === SingleSpa.MOUNTED
    );
  }

  load() {
    return scriptLoader.load(this.url).catch((e) => {
      const errorObj = new Error(`Error loading '${this.name}' bundle from ${this.url}`);
      console.error(errorObj);
      telemetryService.logException(errorObj);
    });
  }
}
