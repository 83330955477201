export enum language {
  'nb-NO' = 'nb-NO',
  'en-US' = 'en-US',
  'en-GB' = 'en-GB',
  'fi-FI' = 'fi-FI',
  'da-DK' = 'da-DK',
  'sv-SE' = 'sv-SE',
  'de-DE' = 'de-DE',
  'nl-NL' = 'nl-NL',
  'pl-PL' = 'pl-PL',
  'fr-FR' = 'fr-FR'
}
export interface JwtTokenPayload {
  iss: string;
  exp: number;
  iat: number;
  sub: string;
  ['deputy.profile']?: string;
  ['deputy.sub']?: string;
  aat: string;
  profile: string;
  locale: keyof typeof language;
  tenant: string;
  ['https://www.catalystone.com/claims/sub_type']: SubjectType;
}

export enum SubjectType {
  DEPUTY_USER = 'deputy_user',
  EXTERNAL_USER = 'external_user',
  USER = 'user'
}

export class JwtToken {
  static JWT_TOKEN_KEY = 'jwt_token';

  get value(): string {
    return this.token;
  }

  constructor(private readonly token: string) {}

  getPayload(): JwtTokenPayload {
    if (this.token) {
      return JSON.parse(window.atob(this.token.split('.')[1]));
    }

    return null;
  }

  getUserId(): string {
    const payload = this.getPayload();
    if (payload) {
      return payload.sub;
    }

    return null;
  }

  getProfileId(): string {
    const payload = this.getPayload();
    if (payload) {
      return payload.profile;
    }

    return null;
  }

  getTenantId(): string {
    const payload = this.getPayload();
    if (payload) {
      return payload.tenant;
    }

    return null;
  }

  getLocale(): language {
    const payload = this.getPayload();
    if (payload) {
      return language[payload.locale];
    }

    return null;
  }

  isExpired(): boolean {
    const payload = this.getPayload();
    return payload && payload.exp * 1000 < new Date().getTime();
  }

  hasDeputy(): boolean {
    return !this.isExpired() && !!this.getPayload()['deputy.profile'];
  }

  getSubjectType(): SubjectType {
    const payload = this.getPayload();
    if (payload) {
      return payload['https://www.catalystone.com/claims/sub_type'];
    }

    return null;
  }

  toString(): string {
    return this.token;
  }
}
