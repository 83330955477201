export enum MessageType {
  REDIRECT = 'REDIRECT_ACTION',
  LOGIN = 'LOGIN_ACTION',
  NO_ACTION = 'NO_ACTION'
}

export interface MessageData {
  type: MessageType;
}

export interface RedirectData extends MessageData {
  redirect: {
    target: string;
  };
}

export interface LoginData extends MessageData {
  auth: LoginResponse;
}

export interface LoginResponse {
  authenticated: boolean;
  token: string;
}
