import { ApplicationInsights, IConfig, IConfiguration } from '@microsoft/applicationinsights-web';

type TelemetryConfig = IConfig & IConfiguration;

/**
 * Defaults for the Application Insights configuration.
 */
const DEFAULT_CONFIG: TelemetryConfig = {
  enableAutoRouteTracking: true,
  disableCorrelationHeaders: false,
  enableCorsCorrelation: true,
  isBeaconApiDisabled: false,
  samplingPercentage: 25
};

const IGNORE_REMOTE_DEPENDENCY = new RegExp(['assets/icons', '\\.svg$'].map((it) => `(${it})`).join('|'));

export class TelemetryService {
  appInsights: ApplicationInsights;
  initialized = false;

  /**
   * Initialize Application Insights.
   *
   * @param config the application insights config
   */
  initialize(config: TelemetryConfig): boolean {
    if (!config.instrumentationKey) {
      console.warn('No application insights instrumentation key is configured. Telemetry will be disabled.');
      return false;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        ...DEFAULT_CONFIG,
        ...config
      }
    });

    this.appInsights.loadAppInsights();

    this.appInsights.addTelemetryInitializer((envelope) => {
      const IGNORE_ITEM = false;
      const ALLOW_ITEM = true;
      const IGNORE_ERROR_PATTERN = /NG0205/i;
      if (
        envelope.baseType === 'RemoteDependencyData' &&
        envelope.baseData !== undefined &&
        (envelope.baseData.responseCode === 0 ||
          (envelope.baseData.responseCode === 200 && IGNORE_REMOTE_DEPENDENCY.test(envelope.baseData.name)))
      ) {
        return IGNORE_ITEM;
      }

      // CO-2884: To ignore Angular's destroyed injector when language is changed
      // Check if the error message contains "NG0205" and ignore if true
      if (
        envelope.baseType === 'ExceptionData' &&
        envelope.baseData?.exceptions.some((exception) => IGNORE_ERROR_PATTERN.test(exception.message))
      ) {
        return IGNORE_ITEM;
      }

      return ALLOW_ITEM;
    });

    this.appInsights.addTelemetryInitializer((envelope) => {
      envelope.tags['ai.cloud.role'] = 'ui-root';
      envelope.tags['ai.cloud.roleInstance'] = 'ui-root';
    });

    this.initialized = true;
    window['__cxAppInsights'] = this.appInsights;
    return true;
  }

  logPageView(name?: string, url?: string) {
    if (!this.initialized) {
      return;
    }

    this.appInsights.trackPageView({
      name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (!this.initialized) {
      return;
    }

    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (!this.initialized) {
      return;
    }

    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    if (!this.initialized) {
      return;
    }

    this.appInsights.trackException({ exception, severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (!this.initialized) {
      return;
    }

    this.appInsights.trackTrace({ message }, properties);
  }
}

export const telemetryService = new TelemetryService();
