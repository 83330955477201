import * as amplitude from '@amplitude/analytics-browser';
import { environmentService } from '../environment.service';

type Amplitude = typeof amplitude;
export type AmplitudeReturn = amplitude.Types.AmplitudeReturn<void> | void;

declare global {
  interface Window {
    amplitude: Amplitude;
    amplitudeDev: Amplitude;
  }
}

export interface UserAnalyticsMetadata {
  [key: string]: string | number | boolean | object;
}

export interface UserAnalyticsIdentity {
  visitor?: UserAnalyticsMetadata;
  account?: UserAnalyticsMetadata;
}

class UserAnalyticsService {
  initialize: () => Promise<[PromiseSettledResult<AmplitudeReturn>]> = async () => {
    const amplitudePromise = amplitude.init(environmentService.env.amplitudeKey, null, {
      serverZone: amplitude.Types.ServerZone.EU,
      disableCookies: true
    });
    (window as any)['amplitude'] = amplitude;
    return Promise.allSettled([amplitudePromise]);
  };
}

export const userAnalyticsService = new UserAnalyticsService();
