import { serviceDownComponent, telemetryService } from '../../application';
import { environmentService } from '../environment.service';
import { BundleConfig } from '../models';
import { bundleDevtools } from '../utilities/devtools';
import { configLoader } from './config-loader';

class BundleConfigService {
  private _bundleConfig: BundleConfig[];

  constructor() {}

  getConfig(locale?: string): Promise<BundleConfig[]> {
    if (!this._bundleConfig) {
      const localConfig = configLoader.loadJSON<BundleConfig[]>('./assets/bundle-config.json');

      const globalConfig = configLoader.loadJSON<BundleConfig[]>(
        this.buildUrl(`${environmentService.env.apiUrl}bundle-config/v1/bundle`, {
          es: 'es2015',
          locale: locale ? locale : ''
        })
      );

      return Promise.all([localConfig, globalConfig])
        .then((res) => {
          this._bundleConfig = res[1];
          if (environmentService.env.devtools) {
            this._bundleConfig = this.updateGlobalWithLocalConfig(res[1], res[0]);
            this._bundleConfig = bundleDevtools(this._bundleConfig);
          }
          return this._bundleConfig;
        })
        .catch((error) => {
          telemetryService.logException(new Error(`An error occurred while loading bundle-config files : ${error}`));
          serviceDownComponent.show();
          throw error;
        });
    }

    return Promise.resolve(this._bundleConfig);
  }

  private buildUrl(baseURL: string, opts?: object): string {
    if (!opts) {
      return baseURL;
    } else {
      const url = new URL(baseURL);
      Object.keys(opts).forEach((opt) => {
        if (opts[opt] !== '') {
          url.searchParams.append(opt, opts[opt]);
        }
      });
      return url.toString();
    }
  }

  async updateLocale(locale: string) {
    this._bundleConfig = null;
    return this.getConfig(locale);
  }

  private updateGlobalWithLocalConfig(globalConfig: BundleConfig[], localConfig: BundleConfig[]) {
    // TODO: Deep clone to avoid modification of loaded configs.
    const mergedConfig: BundleConfig[] = [...globalConfig];

    localConfig.forEach((local) => {
      const global = mergedConfig.find((config) => local.name === config.name);

      if (global) {
        global.url = local.url;
      } else {
        mergedConfig.push(local);
      }
    });

    return mergedConfig;
  }
}

export const bundleConfigService = new BundleConfigService();
