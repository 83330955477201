import { stateService } from '../state/state.service';
import { ApplicationState } from '../shared/application-state.model';
import './loading.scss';

class LoadingService {
  private _isLoading = true;
  private readonly _loadingBar = document.getElementById('loadingBar');

  constructor() {}

  init() {
    stateService.set({ isLoading: this._isLoading });
    stateService.get().subscribe((state: ApplicationState) => {
      if (state.isLoading !== this._isLoading) {
        this._isLoading = state.isLoading;
        this.changeLoadingStateInDom();
      }
    });
  }

  private changeLoadingStateInDom() {
    if (this._isLoading) {
      this._loadingBar.classList.add('loading-bar-show');
    } else {
      this._loadingBar.classList.remove('loading-bar-show');
    }
  }
}

export const loadingService = new LoadingService();
