import * as SingleSpa from 'single-spa';
import { fulfillsAnyPredicate } from '../utilities/activity-functions';

class RoutingService {
  constructor() {}

  bindEvents() {
    window.addEventListener('single-spa:routing-event', this.decideIfApplicationExists, false);
    window.addEventListener('single-spa:before-first-mount', this.firstRedirect, false);
  }

  private decideIfApplicationExists(event: Event): void {
    const loc = (event.target as Window).location;
    if (fulfillsAnyPredicate(loc)) {
      return;
    } else {
      // check if we can load an application
    }
  }

  private firstRedirect(event: Event): void {}
}

export const routingService = new RoutingService();
