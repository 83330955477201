import { serviceDownComponent, telemetryService } from '../application';
import { Environment } from './models';
import { configLoader } from './services';

function getWindow(): any {
  return window;
}

class EnvironmentService {
  env: Environment;

  constructor() {}

  init = async (): Promise<Environment> => {
    return configLoader
      .loadJSON<Environment>('../assets/environment.json')
      .then((variables: Environment) => {
        if (variables) {
          this.env = { ...variables };
          getWindow()['__env'] = this.env;
          return Promise.resolve(this.env);
        }
        return Promise.reject(null);
      })
      .catch((error) => {
        telemetryService.logException(new Error(`error loading environment.json file: ${error}`));
        serviceDownComponent.show();
        throw error;
      });
  };
}

export const environmentService = new EnvironmentService();
