import 'systemjs';
import 'zone.js/dist/zone';

import { telemetryService } from './application';
import { microFrontEnd } from './micro-front-end/micro-front-end.service';
import './styles.scss';

initializeMicrofrontend();

function initializeMicrofrontend(): void {
  microFrontEnd.init().catch((error) => {
    telemetryService.logException(error);
  });
}
