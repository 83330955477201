import { telemetryService } from '../../application/telemetry/telemetry.service';
import { configLoader } from './config-loader';

interface TenantConfig {
  [key: string]: string;
}

class TenantConfigService {
  private _titleWithCOName: string;
  private _titleWithoutCOName: string;

  constructor() {}

  async fetchTitleWithoutCOName(): Promise<string> {
    if (this._titleWithoutCOName) {
      return this._titleWithoutCOName;
    }
    try {
      const response = await configLoader.loadJSON<TenantConfig>(
        'mono/rest/config/portal?configHashKeys=custom.browser.title'
      );
      if (response && response['custom.browser.title']) {
        this._titleWithoutCOName = response['custom.browser.title'];
        return this._titleWithoutCOName;
      }
    } catch (err) {
      telemetryService.logException(new Error(`Failed to load Custom Browser Title: ${err}`));
    }

    return '';
  }

  async browserTitleWithCOName(): Promise<string> {
    if (this._titleWithCOName) {
      return this._titleWithCOName;
    }

    const browserTitle = await this.fetchTitleWithoutCOName();
    this._titleWithCOName = this.titleWithCatalystOneNameConcatter(browserTitle);
    return this._titleWithCOName;
  }

  titleWithCatalystOneNameConcatter(title: string): string {
    return title ? `CatalystOne™ - ${title}` : '';
  }
}

export const tenantConfigService = new TenantConfigService();
