import { Axios } from 'axios';
import isNil from 'lodash/isNil';
import { JwtToken } from '../src/micro-front-end/models';

export class AxiosConfiguration {
  private unprotectedResources: Set<string>;

  constructor() {
    this.unprotectedResources = new Set<string>();
  }

  public registerUnprotectedResources = (urls: string[]) => {
    urls.forEach((_) => this.unprotectedResources.add(_));
  };

  public isUnprotectedResource = (url: string): boolean => {
    return Array.from(this.unprotectedResources).some((_) => url?.includes(_));
  };
}

export const AxiosConfigurationService = (() => {
  let configuration: AxiosConfiguration;

  const createInstance = () => {
    return new AxiosConfiguration();
  };

  return {
    getInstance: () => {
      if (isNil(configuration)) {
        configuration = createInstance();
      }
      return configuration;
    }
  };
})();

export const configureAxiosInterceptor = (axios: Axios) => {
  // configure a JWT inject interceptor for React apps
  axios.interceptors.request.use((config) => {
    if (AxiosConfigurationService.getInstance().isUnprotectedResource(config.url)) {
      return config;
    }

    const token = sessionStorage.getItem(JwtToken.JWT_TOKEN_KEY);
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};
